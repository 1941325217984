import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
import store from './store'
import '@/plugins/plugins.js'
import '@/components/global'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@/utils/scale'

Vue.use(Viewer);
Vue.config.productionTip = false
import {getMenu} from "./utils/request/changePhone";
import changePhoneRouters from '@/router/modules/changePhone.js'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

let checkPermission=(to,next,menu)=>{

    // if (!menu.some(item => {
    //     return item.path === to.path
    // })) {
    //     next(menu[0].path);
    //     return;
    // }
    next()
}
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
        return;
    }

    if (!localStorage.getItem('token')) {
        store.dispatch("setMenu", null);
        store.dispatch('setAdmin', {});
        next('/login');
        return;
    }
    if (!store.state.menu) {
        getMenu().then(res => {
            let menu = res.data&&res.data.menu || []
            store.dispatch('setMenu', menu);
            store.dispatch('setAdmin', res.data&&res.data.admin||{});

            if (!menu.length) {
                alert('获取用户权限失败');
            }
            checkPermission(to,next,menu)
        }).catch(err => {
            console.log(err)
            alert('获取用户权限失败')
        });
    }else {
        checkPermission(to,next,store.state.menu)
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
