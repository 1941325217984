const environments = {
    development: {
        // cdydtestapi.9000ji.cn
        baseURL: 'http://yading.viphfkj.com/channel',
        uploadBaseUrl: 'https://mapi.fanxinghuishou.cn/channel/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    test: {
        baseURL: 'http://yading.viphfkj.com/channel',
        uploadBaseUrl: 'https://mapi.fanxinghuishou.cn/channel/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    release: {
        baseURL: 'http://yading.viphfkj.com/channel',
        uploadBaseUrl: 'https://mapi.fanxinghuishou.cn/channel/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    },
    production: {
        baseURL: 'https://mapi.fanxinghuishou.cn/channel',
        uploadBaseUrl: 'https://mapi.fanxinghuishou.cn/channel/Uploadify/imageUp',
        multiUploadBaseUrl: "https://gateway.laodianbo.com:81/upload/batch",
    }
};

export default environments

