import request from './request'
let type = 'api'
const menu = {
    //图片上传
    imageUp(params){
        return request({
            method:'POST',
            url:'/Uploadify/imageUp',
            data:params,
            type
        })
    },
    postLogin(params){
        return request({
            method:'POST',
            url:'/Admin/doLogin',
            data:params,
            type
        })
    },
    //首页订单列表
    HomeOrderList(params){
        return request({
            method:'POST',
            url:'/Index/index',
            data:params,
            type
        })
    },
    //首页注册信息
    orderNews(params){
        return request({
            method:'POST',
            url:'/index/orderNews',
            data:params,
            type
        })
    },
    //首页右侧订单
    reviewedOrder(params){
        return request({
            method:'POST',
            url:'/Index/reviewedOrder',
            data:params,
            type
        })
    },
    //首页理赔信息
    orderNews2(params){
        return request({
            method:'POST',
            url:'/index/orderNews2',
            data:params,
            type
        })
    },//首页其他信息
    orderNews3(params){
        return request({
            method:'POST',
            url:'/index/orderNews3',
            data:params,
            type
        })
    },

    //卡密查询
    cardPoolList(params){
        return request({
            method:'POST',
            url:'/Card/cardPoolList',
            data:params,
            type
        })
    },
    //卡密列表
    cardBatchList(params){
        return request({
            method:'POST',
            url:'/Card/cardBatchList',
            data:params,
            type
        })
    },
    //合作商下拉列表
    selectGroupList(params){
        return request({
            method:'POST',
            url:'/Group/selectGroupList',
            data:params,
            type
        })
    },
    //审核状态下拉列表
    orderAuditStatus(params){
        return request({
            method:'POST',
            url:'/order/orderAuditStatus',
            data:params,
            type
        })
    },
    //子险种下拉列表
    pullCardGoods(params){
        return request({
            method:'POST',
            url:'/Card/pullCardGoods',
            data:params,
            type
        })
    },
    //保险类型下拉列表
    pullInsTypeList(params){
        return request({
            method:'POST',
            url:'/Insurance/pullInsTypeList',
            data:params,
            type
        })
    },
    openSon(params){
        return request({
            method:'POST',
            url:'/Insurance/openSon',
            data:params,
            type
        })
    },

    //卡号卡密生成检查
    checkCardNum(params){
        return request({
            method:'POST',
            url:'/Card/checkCardNum',
            data:params,
            type
        })
    },
    //卡号卡密生成
    addCardPool(params){
        return request({
            method:'POST',
            url:'/Card/addCardPool',
            data:params,
            type
        })
    },
    //卡号卡密批次修改
    editsCardPool(params){
        return request({
            method:'POST',
            url:'/Card/editsCardPool',
            data:params,
            type
        })
    },
    //卡号卡密单个修改详情
    cardPoolInfo(params){
        return request({
            method:'POST',
            url:'/Card/cardPoolInfo',
            data:params,
            type
        })
    },
    //卡号卡密单个修改
    editCardPool(params){
        return request({
            method:'POST',
            url:'/Card/editCardPool',
            data:params,
            type
        })
    },
    //批次卡号卡密导出
    downloadCardPool(params){
        return request({
            method:'POST',
            url:'/Card/downloadCardPool',
            data:params,
            responseType: 'blob'
        })
    },
    //标记
    cardSend(params){
        return request({
            method:'POST',
            url:'/Card/cardSend',
            data:params,
            type
        })
    },
    //分配标记
    cardGroupSend(params){
        return request({
            method:'POST',
            url:'/Card/cardGroupSend',
            data:params,
            type
        })
    },
    // 分配
    cardBatchGroupList(params){
        return request({
            method:'POST',
            url:'/Card/cardBatchGroupList',
            data:params,
            type
        })
    },
    // 删除
    delCardBatchGroup(params){
        return request({
            method:'POST',
            url:'/Card/delCardBatchGroup',
            data:params,
            type
        })
    },

    // 分配
    addCardBatchGroup(params){
        return request({
            method:'POST',
            url:'/Card/addCardBatchGroup',
            data:params,
            type
        })
    },

//////////////
//////////////
    // 子方案列表
    zifanganList(params){
        return request({
            method:'POST',
            url:'/Card/zifanganList',
            data:params,
            type
        })
    },
    // 添加、修改子方案列表
    zifanganAdd(params){
        return request({
            method:'POST',
            url:'/Card/zifanganAdd',
            data:params,
            type
        })
    },
    zifangAis(params){
        return request({
            method:'POST',
            url:'/Card/zifangAis',
            data:params,
            type
        })
    },
    // 子险种列表
    cardGoodsList(params){
        return request({
            method:'POST',
            url:'/Card/cardGoodsList',
            data:params,
            type
        })
    },

    // 子险种列表
    addCardGoods(params){
        return request({
            method:'POST',
            url:'/Card/addCardGoods',
            data:params,
            type
        })
    },

    // 子险种列表
    pullZifangan(params){
        return request({
            method:'POST',
            url:'/Card/pullZifangan',
            data:params,
            type
        })
    },

    // 分销商列表
    groupFxsList(params){
        return request({
            method:'POST',
            url:'/Group/groupFxsList',
            data:params,
            type
        })
    },
    //省份
    getRegion(params){
        return request({
            method:'POST',
            url:'/index/getRegion2',
            data:params,
            type
        })
    },
    //分销商删除
    delGroupFxs(params){
        return request({
            method:'POST',
            url:'/Group/delGroupFxs',
            data:params,
        })
    },
    //分销商新增修改
    addGroupFxs(params){
        return request({
            method:'POST',
            url:'/Group/addGroupFxs',
            data:params,
        })
    },
    //分销商弹窗列表
    getFxsGroup(params){
        return request({
            method:'POST',
            url:'/Group/getFxsGroup',
            data:params,
        })
    },
    //分销商排序
    editGroupFxs(params){
        return request({
            method:'POST',
            url:'/Group/editGroupFxs',
            data:params,
        })
    },
    //品牌列表
    phoneBrandList(params){
        return request({
            method:'POST',
            url:'/Phone/phoneBrandList',
            data:params,
        })
    },

    //品牌新增修改
    addPhoneBrand(params){
        return request({
            method:'POST',
            url:'Phone/addPhoneBrand',
            data:params,
        })
    },
    //品牌删除
    delPhoneBrand(params){
        return request({
            method:'POST',
            url:'Phone/delPhoneBrand',
            data:params,
        })
    },
    //规格列表
    phoneRam(params){
        return request({
            method:'POST',
            url:'Phone/phoneRam',
            data:params,
        })
    },
    //规格修改
    addPhoneRam(params){
        return request({
            method:'POST',
            url:'Phone/addPhoneRam',
            data:params,
        })
    },
    //规格修改
    delPhoneRam(params){
        return request({
            method:'POST',
            url:'Phone/delPhoneRam',
            data:params,
        })
    },

     //机型列表
     phonePriceList(params){
        return request({
            method:'POST',
            url:'Phone/phonePriceList',
            data:params,
        })
    },
    //机型价格删除
    delPhonePrice(params){
        return request({
            method:'POST',
            url:'Phone/delPhonePrice',
            data:params,
        })
    },
    //机型价格详情
    phonePriceInfo(params){
        return request({
            method:'POST',
            url:'Phone/phonePriceInfo',
            data:params,
        })
    },
     //机型价格新增
     addPhonePrice(params){
        return request({
            method:'POST',
            url:'Phone/addPhonePrice',
            data:params,
        })
    },

    //机型价格修改
    phonePriceEdit(params){
        return request({
            method:'POST',
            url:'Phone/phonePriceEdit',
            data:params,
        })
    },
    //机型模板下载
    downloadPhone(params){
        return request({
            method:'GET',
            url:'Phone/downloadPhonePrice2',
            params:params,
            responseType: 'blob'
        })
    },

    //机型导入
    uploadPhone(params){
        return request({
            method:'POST',
            url:'Phone/uploadPhonePrice2',
            data:params,
            // responseType: 'blob'
        })
    },

    //保险类型列表-（注册协议及说明）
    insuranceTypeList(params){
        return request({
            method:'POST',
            url:'Insurance/insuranceTypeList',
            data:params,
        })
    },
    //保险类型列表-（列表）
    articlelist(params){
        return request({
            method:'POST',
            url:'Article/articlelist',
            data:params,
        })
    },
    //保险类型列表-（详情）
    insuranceTypeInfo(params){
        return request({
            method:'POST',
            url:'Insurance/insuranceTypeInfo',
            data:params,
        })
    },
    //保险类型列表-（详情修改）
    editInsuranceType(params){
        return request({
            method:'POST',
            url:'Insurance/editInsuranceType',
            data:params,
        })
    },
    //注意事项-（注意事项详情）
    articleInfo(params){
        return request({
            method:'POST',
            url:'Article/articleInfo',
            data:params,
        })
    },
    //注意事项-（注意事项详情修改）
    editArticle(params){
        return request({
            method:'POST',
            url:'Article/editArticle',
            data:params,
        })
    },

    //注意事项-（注意事项详情修改）
    orderList(params){
        return request({
            method:'POST',
            url:'Order/orderList',
            data:params,
        })
    },

    //订单详情-注册单详情
    orderInfo(params){
        return request({
            method:'POST',
            url:'/Order/orderInfo',
            data:params,
        })
    },
    //机型-下拉
    pullModelList(params){
        return request({
            method:'POST',
            url:'/Phone/pullModelList',
            data:params,
        })
    },
    //换机保，全面保-损坏配置
    damageConfig(params){
        return request({
            method:'POST',
            url:'/order/damageConfig',
            data:params,
        })
    },
    //换机保，全面保-理赔服务
    insService(params){
        return request({
            method:'POST',
            url:'/order/insService',
            data:params,
        })
    },
    getRelationType(params){
        return request({
            method:'POST',
            url:'/order/getRelationType',
            data:params,
        })
    },
    //安卓换机完成-换机方式
    insServiceAn(params){
        return request({
            method:'POST',
            url:'/order/insServiceAn',
            data:params,
        })
    },

    //更换IMEI原因
    replaceType(params){
        return request({
            method:'POST',
            url:'/order/replaceType',
            data:params,
        })
    },

    //微信推送
    sendWxPush(params){
        return request({
            method:'POST',
            url:'/Order/sendWxPush',
            data:params,
        })
    },
    //微信推送
    orderExamine(params){
        return request({
            method:'POST',
            url:'/Order/orderExamine',
            data:params,
        })
    },
    //维修完成-维修类型
    getFinishType(params){
        return request({
            method:'POST',
            url:'/order/getFinishType',
            data:params,
        })
    },
    //维修完成-维修方式
    getFinishMode(params){
        return request({
            method:'POST',
            url:'/order/getFinishMode',
            data:params,
        })
    },


    //维修商下拉
    selectRepairList(params){
        return request({
            method:'POST',
            url:'/Repair/selectRepairList',
            data:params,
        })
    },
    //回收订单列表
    recycleList(params){
        return request({
            method:'POST',
            url:'/Order/recycleList',
            data:params,
        })
    },
    //回收订单类型下拉
    orderMold(params){
        return request({
            method:'POST',
            url:'/Order/orderMold',
            data:params,
        })
    },
    //订单入回收站
    delOrder(params){
        return request({
            method:'POST',
            url:'/Order/delOrder',
            data:params,
        })
    },
    //订单回收操作
    recycleHandle(params){
        return request({
            method:'POST',
            url:'/Order/recycleHandle',
            data:params,
        })
    },
    //订单回收批量操作
    recycleBatch(params){
        return request({
            method:'POST',
            url:'/Order/recycleBatch',
            data:params,
        })
    },
    //网络制式-下拉
    pullNetList(params){
        return request({
            method:'POST',
            url:'/Phone/pullNetList',
            data:params,
        })
    },
    //菜单
    getMenu(params){
        return request({
            method:'POST',
            url:'/Index/index',
            data:params,
        })
    },
    //保留原图
    retainOldPic(params){
        return request({
            method:'POST',
            url:'/Order/retainOldPic',
            data:params,
        })
    },
    //数据导出
    exportExcel(params){
        return request({
            method:'GET',
            url:'/Excel/exportExcel',
            params,
            responseType: 'blob',
            timeOut:10000000
        })
    },
    //数据导出
    exportExcel2(params){
        return request({
            method:'POST',
            url:'/Excel/exportExcel2',
            data:params,
            timeOut:10000000
        })
    },

    damageConfig2(params){
        return request({
            method:'POST',
            url:'/Order/damageConfig2',
            data:params,
        })
    },
    nnhxService(params){
        return request({
            method:'POST',
            url:'/Order/nnhxService',
            data:params,
        })
    },
    get3032Bal(params){
        return request({
            method:'GET',
            url:'/Passageway/get3032Bal',
            data:params,
        })
    },
    insTypeSonList(params){
        return request({
            method:'POST',
            url:'/Insurance/insTypeSonList',
            data:params,
        })
    },
    addInsTypeSon(params){
        return request({
            method:'POST',
            url:'/Insurance/addInsTypeSon',
            data:params,
        })
    },
    section(params){
        return request({
            method:'POST',
            url:'/Insurance/section',
            data:params,
        })
    },
    section2(params){
        return request({
            method:'POST',
            url:'/Insurance/section2',
            data:params,
        })
    },
    idCardConfig(params){
        return request({
            method:'POST',
            url:'/System/idCardConfig',
            data:params,
        })
    },
    getIdCard(params){
        return request({
            method:'POST',
            url:'/System/getIdCard',
            data:params,
        })
    },
}
export default menu



