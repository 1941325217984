<template>
  <div class="GlobalInfoBar">
    <div class="top">
      <div class="top_line"></div>
      <div class="title_top">{{title}}</div>
      <div style="margin-left:20px;color: #999">{{subtitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalInfoBar',
  props: {
    title: String,
    subtitle: String,
    content: String,
    // type: common/hint
    type: {
      type: String,
      default: 'common'
    },
    // icon: shop/recycle
    icon: {
      type: String,
      default: 'shop'
    }
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalInfoBar{
  // width:calc(100% + 40px);
  width: 100%;
}
.top{
  height: 52px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.top_line{
  width: 4px;
  height: 14px;
  background: #1091FF;
  border-radius: 2px;
  margin: 0 15px 0 20px;
}
.title_top{
  font-weight: bold;
  font-size: 18px;
  font-family: FZLanTingHei-EB-GBK;
  color: #333333;
}
</style>
